import React from 'react';
import { Container } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import apartment from '../../images/apartment_construction_1.jpg';
import electric_vehicle from '../../images/electric_vehicle_charger.jpg';
import high_voltage_panel from '../../images/high_voltage_panel.jpg';
import conduit from '../../images/conduit.jpg';
import commercial_gas_station from '../../images/commercial_gas_station.jpg';
import industial from '../../images/industrial.jpg';
import apartment_2 from '../../images/apartment_construction_2.jpg';

const Portfolio = () => {

  const images = [
    {
      original: apartment,
      thumbnail: apartment,
    },
    {
      original: electric_vehicle,
      thumbnail: electric_vehicle,
    },
    {
      original: commercial_gas_station,
      thumbnail: commercial_gas_station,
    },

    {
      original: conduit,
      thumbnail: conduit,
    },
    {
      original: high_voltage_panel,
      thumbnail: high_voltage_panel,
    },
    {
      original: industial,
      thumbnail: industial
    },
    {
      original: apartment_2,
      thumbnail: apartment_2,
    },
  ];
  return (
    <>
      {/* <Header></Header> */}
      <Container>
        {/* <h3 className="pe-header-secondary">Portfolio</h3> */}
        <ImageGallery items={images} autoPlay={true} />
        <img ></img>
      </Container>
      {/* <Footer></Footer> */}
    </>
  );
};

export default Portfolio;